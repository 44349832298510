import {
  BooleanProperty,
  ExternalServiceId,
  ExternalServiceIdProperty,
  InternalId,
  InternalIdProperty,
  NestedProperty,
  Uuid,
  UuidProperty,
} from '@edgebox/data-definition-kit';
import { SyndicationStatus, SyndicationType } from '@edgebox/sync-core-data-definitions';
import { ClientSiteEntity, ClientSyndicationEntity, ClientSyndicationUsageSummary } from '@edgebox/sync-core-rest-client';
import { faArrowAltRight } from '@fortawesome/pro-solid-svg-icons/faArrowAltRight';
import { faBan } from '@fortawesome/pro-light-svg-icons/faBan';
import { faQuestion } from '@fortawesome/pro-light-svg-icons/faQuestion';
import { faTimes } from '@fortawesome/pro-light-svg-icons/faTimes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React from 'react';
import { ISyncCoreApiComponentState, SyncCoreApiComponent } from '../../../services/SyncCoreApiComponent';
import { SiteName } from '../../SiteName';
import { UsageSummary } from '../../UsageSummary';
import { ParamsComponent } from '../ParamsComponent';
import { doneStatus, SyndicationStatusIcon } from '../SyndicationStatusIcon';
import { formatDuration } from '../../../Helpers';
import { ExternalLinkWithIcon } from '../../ExternalLinkWithIcon';

interface IProps {
  params: Params;
  recent?: ClientSyndicationUsageSummary[];
}

interface IState extends ISyncCoreApiComponentState {
  site?: ClientSiteEntity;
  recent?: ClientSyndicationUsageSummary[];
  selected?: number;
  updatedAt?: number;
  updateCount?: number;
}

class EntityReference {
  // Entity type
  @ExternalServiceIdProperty(false)
  namespaceMachineName?: ExternalServiceId;
  @ExternalServiceIdProperty(false)
  machineName?: ExternalServiceId;

  // Unique entity ID
  @UuidProperty(false)
  remoteUuid?: Uuid;
  @ExternalServiceIdProperty(false)
  remoteUniqueId?: ExternalServiceId;
}

const IF_ID_EMPTY = { property: 'id', in: [undefined, null, ''] };
class Params {
  @InternalIdProperty(false)
  id?: InternalId;

  // Entity type
  @ExternalServiceIdProperty(IF_ID_EMPTY)
  namespaceMachineName?: ExternalServiceId;
  @ExternalServiceIdProperty(IF_ID_EMPTY)
  machineName?: ExternalServiceId;

  // Unique entity ID
  @UuidProperty(false)
  remoteUuid?: Uuid;
  @ExternalServiceIdProperty(false)
  remoteUniqueId?: ExternalServiceId;

  @NestedProperty(false, () => EntityReference)
  rootEntities?: EntityReference[];

  @BooleanProperty(false)
  fromRecentActivity?: boolean;
}

const REFRESH_INTERVAL_RUNNING = [1_000, 2_000, 4_000, 7_000, 10_000];
const REFRESH_INTERVAL_STILL = 60_000;

export class UpdateStatusBoxWithParams extends SyncCoreApiComponent<IProps, IState> {
  async load(): Promise<Partial<IState>> {
    this.setState({});

    let site: ClientSiteEntity | undefined = this.state.site;

    if (!site) {
      site = await this.getCurrentSite();

      if (!site) {
        return {};
      }
    }

    const { id, remoteUniqueId, remoteUuid, namespaceMachineName, machineName, rootEntities, fromRecentActivity } = this.props.params;

    let recent: ClientSyndicationUsageSummary[];
    if (id) {
      const mostRecent = await this.api.syndication.syndications.usageSummary(id);
      recent = [mostRecent];
    } else if (this.props.recent) {
      recent = this.props.recent;
    } else if (
      !rootEntities?.length ||
      (rootEntities.length === 1 && (rootEntities[0].remoteUniqueId ?? rootEntities[0].remoteUuid) === (remoteUniqueId ?? remoteUuid))
    ) {
      recent = (
        await this.api.syndication.syndications.usageSummaryForSite(
          site.uuid,
          { namespaceMachineName: namespaceMachineName!, machineName: machineName! },
          remoteUniqueId ? { remoteUniqueId } : { remoteUuid: remoteUuid! },
          { includingMigrations: true, isRegularSyndication: true }
        )
      ).items;
    } else {
      const allRecent = await Promise.all(
        rootEntities.map(
          async (reference) =>
            (
              await this.api.syndication.syndications.usageSummaryForSite(
                site!.uuid,
                reference.namespaceMachineName && reference.machineName
                  ? { namespaceMachineName: reference.namespaceMachineName, machineName: reference.machineName }
                  : undefined,
                reference.remoteUniqueId ? { remoteUniqueId: reference.remoteUniqueId } : { remoteUuid: reference.remoteUuid! },
                { includingMigrations: true, isRegularSyndication: true }
              )
            ).items
        )
      );

      allRecent.sort((a, b) => {
        if (!a.length) {
          return b.length ? -1 : 0;
        }
        if (!b.length) {
          return 1;
        }
        return b[0].thisSite.createdAt.valueOf() - b[0].thisSite.createdAt.valueOf();
      });

      recent = allRecent[0] ?? [];
    }

    // When pushing manually, the syndication entity is not created immediately
    // but asynchronously based on the migration entity. So there's a short time
    // span when this yields no results yet which becomes more problematic
    // when there's e.g. a queue slow down.
    // So if we're certain that there must be an update, we retry grabbing it.
    if (!recent.length && fromRecentActivity) {
      await new Promise((resolve) => setTimeout(resolve, 1_000));
      return await this.load();
    }

    const isSyndicationRunning = (status?: SyndicationStatus) => {
      return status === SyndicationStatus.Initializing || status === SyndicationStatus.Running || status === SyndicationStatus.Retrying;
    };

    const isRunning = !!recent.find(
      (summary) =>
        isSyndicationRunning(summary.thisSite?.status) ||
        isSyndicationRunning(summary.sourceSite?.status) ||
        isSyndicationRunning(summary.targetSite?.status) ||
        !!summary.targetSummary?.find((c) => isSyndicationRunning(c.status))
    );

    let updateCount = this.state.updateCount ? this.state.updateCount + 1 : 0;

    if (isRunning) {
      if (this.__refreshInterval) {
        clearInterval(this.__refreshInterval);
        this.__refreshInterval = null;
      }

      setTimeout(
        this.load,
        updateCount >= REFRESH_INTERVAL_RUNNING.length
          ? REFRESH_INTERVAL_RUNNING[REFRESH_INTERVAL_RUNNING.length - 1]
          : REFRESH_INTERVAL_RUNNING[updateCount]
      );
    } else {
      if (!this.__refreshInterval) {
        this.__refreshInterval = setTimeout(() => {
          if (!this.__isMounted) {
            clearInterval(this.__refreshInterval);
            this.__refreshInterval = null;
            return;
          }

          this.setState({ updatedAt: moment().valueOf(), updateCount: 1 });
        }, REFRESH_INTERVAL_STILL);
      }
    }

    return {
      site,
      recent,
      updateCount,
      updatedAt: moment().valueOf(),
    };
  }

  protected __refreshInterval: any = null;

  render() {
    const { site, recent } = this.state;

    if (!site || !recent) {
      return this.renderRequest();
    }

    const summary = recent[0];

    if (!summary) {
      return <div className="text-muted">This content has not been pushed from or pulled into this site yet.</div>;
    }

    const isSourceSite =
      summary.thisSite?.type === SyndicationType.RetrieveEntity ||
      summary.thisSite?.type === SyndicationType.RetrieveAndPushEntity ||
      summary.thisSite?.type === SyndicationType.RetrieveAndDeleteEntity ||
      summary.thisSite?.type === SyndicationType.RetrieveAndDeleteEmbeddedEntity ||
      summary.thisSite?.type === SyndicationType.RetrieveConfig;

    const sourceSite = isSourceSite ? summary.thisSite : summary.sourceSite;
    const targetSite = isSourceSite ? summary.targetSite : summary.thisSite;

    const allDone =
      (!summary.targetSummary?.length || !summary.targetSummary.find((c) => !doneStatus.includes(c.status))) &&
      summary.finishedAt &&
      (!summary.thisSite || doneStatus.includes(summary.thisSite.status));
    const finishedAt = allDone ? summary.finishedAt : undefined;

    const noTargetReason =
      summary.thisSite?.type === SyndicationType.RetrieveConfig ? (allDone ? 'Exported config' : 'Exporting config...') : undefined;

    const renderSingleSite = (syndication?: ClientSyndicationEntity) => {
      const isThisSite = isSourceSite ? syndication === sourceSite : syndication === targetSite;

      const element = (
        <div>
          <span className="d-inline-block" style={{ height: '32px', verticalAlign: 'middle' }}>
            {syndication ? (
              <SyndicationStatusIcon status={syndication!.status} errorType={syndication?.operations?.[0]?.errors?.[0]?.type} />
            ) : (
              <FontAwesomeIcon icon={faQuestion} className="text-muted" />
            )}
          </span>
          <span className="d-inline-block" style={{ lineHeight: '32px', verticalAlign: 'middle' }}>
            {syndication?.usage ? (
              <UsageSummary
                entity={syndication.usage}
                viewLabel={
                  isThisSite ? (
                    'this site'
                  ) : (
                    /* The box will be 470px wide when embedded. So with all the other content around it, the site name can be max 90px wide. */
                    /* TODO: Use flex box for this. */
                    <>
                      <SiteName
                        id={syndication.targetSite.getId()}
                        className="align-top text-decoration-underline"
                        style={{ maxWidth: '90px' }}
                        withTitle
                      />
                    </>
                  )
                }
              />
            ) : isThisSite && isSourceSite && syndication?.rootEntityDetails?.viewUrl ? (
              <ExternalLinkWithIcon to={syndication.rootEntityDetails.viewUrl}>this site</ExternalLinkWithIcon>
            ) : undefined}
          </span>
        </div>
      );

      return element;
    };

    const renderIcon = (icon: any) => {
      return (
        <div className="p-1">
          <FontAwesomeIcon
            icon={icon}
            className={icon === faBan || icon == faQuestion ? 'text-light' : 'text-muted'}
            style={{ '--fa-primary-color': 'var(--primary)' } as any}
          />
        </div>
      );
    };

    const isRunningOrRetrying = !![SyndicationStatus.Initializing, SyndicationStatus.Running, SyndicationStatus.Retrying].find(
      (c) => c === summary.thisSite?.status || c === summary.sourceSite?.status || c === summary.targetSite?.status
    );

    return (
      <div className="p-0">
        <div className="d-flex p-0 m-0">
          <div className={'p-0 flex-grow-0 flex-shrink-0'}>{renderSingleSite(sourceSite)}</div>
          {noTargetReason ? (
            <div className="py-1 px-0 flex-grow-0 flex-shrink-0 text-muted">{noTargetReason}</div>
          ) : (
            <>
              <div className="py-1 ps-3 pe-1 flex-grow-0 flex-shrink-0">
                <FontAwesomeIcon icon={faArrowAltRight} className="text-light" />
              </div>
              <div className={'p-0 flex-grow-0 flex-shrink-1'}>
                {targetSite
                  ? renderSingleSite(targetSite)
                  : summary.targetSummary
                    ? summary.targetSummary.length
                      ? summary.targetSummary.map((summary, index) => {
                          return (
                            <span key={index}>
                              <span className="d-inline-block" style={{ height: '32px', verticalAlign: 'middle' }}>
                                <SyndicationStatusIcon status={summary.status} />
                              </span>
                              <span className="d-inline-block text-muted" style={{ lineHeight: '32px', verticalAlign: 'middle' }}>
                                <strong>{summary.count}</strong>x
                              </span>
                            </span>
                          );
                        })
                      : renderIcon(faTimes)
                    : isRunningOrRetrying
                      ? renderIcon(faQuestion)
                      : renderIcon(faBan)}
              </div>
            </>
          )}
          {finishedAt ? (
            <div className="py-1 px-2 flex-grow-0 flex-shrink-0 text-muted">
              in {summary.thisSite.duration ? formatDuration(summary.thisSite.duration) : finishedAt.from(summary.thisSite.createdAt, true)}
            </div>
          ) : undefined}
        </div>
      </div>
    );
  }
}

export const UpdateStatusBox = () => (
  <ParamsComponent<Params> Params={Params}>
    {(params: Params) => {
      return <UpdateStatusBoxWithParams params={params} />;
    }}
  </ParamsComponent>
);
